//https://colorhunt.co/palette/165293
.App-logo{
  display: block;
  height: 200px;
  border-radius: 100%;
  margin: 0px auto;
  // transform: translate(0px, 50%);
  border: 4px solid #fff;
}

.App{
  // background:red;
  width: 700px;
  margin: 0px auto;
  overflow: hidden;
  
  .page{
    transform: translate(0px, 10%);
    position: relative;
  }
}




.description{
  margin: 0px auto;
  // transform: translate(0px, 35%);
  .name{
    margin-top: 12px;
    margin-bottom: 0px;
  }
  .title{
    // font-size: 1.5em;
    margin-top: 0px;
  }
}

body{
  font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Roboto,Arial,Hiragino Sans,Hiragino Kaku Gothic ProN,Meiryo,sans-serif;
  line-height: 1.57;
  font-size: 1.0rem;
}

.sub-head {
  text-align: center;
}

html, body { 
  height: 100%;
  width: 100%;
  margin: 0;
  overflow:auto;
}
div { height: 100%; width: 100%; background: #f4f4f4; }

*{
  color: #232020;
}
.sm-icon-sec{
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0px);
  .sm-icon{
    height: 20px;
    margin: 0px 5px;
  }
}

h1{
  font-weight: 200;
  font-size: 2.5em;
}

h2{
  font-weight: 100;
  font-size: 1.3em;
}

.about{
  margin-top: 40px;
}


@media only screen and (max-width: 500px) {
  .App {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .App .page{
    transform: translate(0px, 2%);
  }
  .about{
    margin-top: 18px;
  }
}